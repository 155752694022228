<ng-container
  ><aside [cdkTrapFocus]="open" [class.drawer-shown]="open">
    <nav class="drawer">
      <a elder-toggle class="drawer__toggle" tabindex="0" [toggled]="true" (click)="emitToggle()">
        <elder-animated-x toggle-icon></elder-animated-x>
      </a>

      <elder-brand class="drawer__brand"></elder-brand>
      <ng-content></ng-content>
    </nav>

    <a
      (click)="emitToggle()"
      class="drawer__close"
      title="Close Menu"
      aria-label="Close Menu"
      tabindex="0"
    ></a>
  </aside>
</ng-container>
