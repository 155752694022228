<h2 mat-dialog-title>ElderDrug.com Disclaimer</h2>
<div mat-dialog-content>
  <p>
    All the information on elderdrug.com is published in good faith and for general information
    purpose only. Elderdrug.com does not make any warranties about the completeness, reliability and
    accuracy of this information. Any action you take upon the information you find on this website
    (elderdrug.com), is strictly at your own risk. Elderdrug.com and associated domains will not be
    liable for any losses and/or damages in connection with the use of our website. From our
    website, you can visit other websites by following hyperlinks to such external sites. While we
    strive to provide only quality links to useful and ethical websites, we have no control over the
    content and nature of these sites. These links to other websites do not imply a recommendation
    for all the content found on these sites. Site owners and content may change without notice and
    may occur before we have the opportunity to remove a link which may have gone ‘bad'. Please be
    also aware that when you leave our website, other sites may have different privacy policies and
    terms which are beyond our control. Please be sure to check the Privacy Policies of these sites
    as well as their "Terms of Service" before engaging in any business or uploading any
    information.
  </p>
  <p>
    <em>By using elderdrug.com, you hereby consent to this disclaimer and agree to its terms.</em>
  </p>
  <p>Should updates or amendments be made to elderdrug.com, those changes will be posted here.</p>
</div>
<br />
<div style="justify-content: center" mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
</div>
