<ng-container
  *elderLet="{
    pinSearch: layoutService.pinnedSearch$ | async,
    typeahead: typeaheadState$ | async,
    mobile: layoutService.mobile$ | async
  } as state"
>
  <form
    #drugform
    class="form"
    [cdkTrapFocus]="state.mobile"
    (ngSubmit)="search($event)"
    [formGroup]="drugsGroup"
  >
    <div class="form__inner-wrapper" [class.is-widened]="state.pinSearch">
      <div class="search" cdkMonitorSubtreeFocus (cdkFocusChange)="setFocusStatus($event, -1)">
        <label class="search__label" for="searchinput">Add Drugs</label>
        <div
          class="search__field"
          #autoOrigin="matAutocompleteOrigin"
          matAutocompleteOrigin
          [class.is-missing]="drugInput.errors?.termabsent"
          [class.is-invalid]="drugInput.invalid && drugInput.value.length"
          [class.is-pinned]="state.pinSearch"
          [class.is-dirty]="!searchInput.value.length"
          [class.is-open]="searchInputAutoTrigger.panelOpen"
        >
          <ng-container *ngIf="state.mobile">
            <mat-icon
              *ngIf="!drugList.length || !state.pinSearch; else toggle"
              svgIcon="search"
              aria-hidden="true"
              class="decorative-search-icon"
            ></mat-icon>

            <ng-template #toggle>
              <button
                elder-toggle
                (toggle)="layoutService.toggleSidenav(false)"
                [matTooltip]="'Go back'"
                [toggled]="true"
                diameter="44px"
                iconScale="0.7"
                aria-label="Go back"
                class="icon-button--back"
              >
                <mat-icon toggle-icon svgIcon="arrow_left"></mat-icon></button
            ></ng-template>
          </ng-container>

          <input
            #searchInput
            #searchInputAutoTrigger="matAutocompleteTrigger"
            class="search__input"
            type="text"
            id="searchinput"
            formControlName="drugInput"
            placeholder="Zolpidem"
            aria-label="Search"
            enterkeyhint="search"
            aria-describedby="errors"
            [matAutocomplete]="autocomplete"
            [matAutocompleteConnectedTo]="autoOrigin"
            [matAutocompleteDisabled]="searchInput.value.length === 0"
            (keydown.enter)="searchInput.value.length || search($event)"
          />

          <div class="search__spinner-wrapper" *ngIf="state.typeahead.pending; else searchControls">
            <mat-spinner diameter="25"></mat-spinner>
          </div>
          <ng-template #searchControls>
            <span>
              <button
                #add
                (click)="addListControl(); searchInput.focus(); $event.preventDefault()"
                matTooltip="Add {{ searchInput.value || '' }} to search"
                matTooltipPosition="above"
                matRipple
                [matRippleCentered]="true"
                [matRippleUnbounded]="true"
                [disabled]="!drugInput.valid"
                class="icon-button--add"
                attr.aria-label="Add {{ searchInput.value || '' }} to search"
              >
                <mat-icon inline="true" svgIcon="add-2"></mat-icon></button
            ></span>
          </ng-template>
          <button
            *ngIf="state.pinSearch || !state.mobile"
            (click)="search($event, true); searchInput.focus(); $event.preventDefault()"
            class="icon-button--search"
            [class.is-mobile]="state.mobile"
            elder-icon-button
            [disabled]="!drugInput.valid && !drugList.length"
          >
            <mat-icon toggle-icon svgIcon="search"></mat-icon>
          </button>
        </div>
        <elder-error-message
          [errorMessages]="errorMessages"
          [warningMessages]="warningMessages"
          [errors]="drugInput.errors"
        ></elder-error-message>
      </div>
      <!--I would normally decouple these two sections, but because the same autocomplete is shared amongst the two, it's difficult to do so-->
      <section class="edit__section" [class.is-pinned]="state.pinSearch">
        <ng-template #directions>
          <elder-directions
            *ngIf="!state.pinSearch && state.mobile && layoutService.showDirections$ | async"
            class="search-directions small"
            [style.--directions-width]="state.pinSearch ? '89%' : '93%'"
            [hideDescription]="true"
          >
          </elder-directions>
        </ng-template>
        <ul
          class="edit__list"
          #chipList
          formArrayName="drugList"
          aria-label="Drug selection"
          *ngIf="drugList.length > 0 || state.pinSearch; else directions"
        >
          <h2 class="edit__title">
            Selected Drugs
            <button (click)="clearSearchTerms($event)" class="edit__button--clear">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M15 16h4v2h-4zm0-8h7v2h-7zm0 4h6v2h-6zM3 18c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V8H3v10zM14 5h-3l-1-1H6L5 5H2v2h12z"
                />
              </svg>
            </button>
          </h2>
          <div class="edit__items-wrapper">
            <div
              id="multi-search-label"
              [class.cdk-visually-hidden]="drugList.length"
              class="add-item-label edit__item"
            >
              Tap <mat-icon style="display: inline" svgIcon="add-2"></mat-icon> to add multiple
              search terms.
            </div>
            <li
              *ngFor="let drugName of drugList.controls; index as index"
              class="edit__item"
              cdkMonitorSubtreeFocus
              (cdkFocusChange)="setFocusStatus($event, index)"
            >
              <div
                class="item__content"
                #editOrigin="matAutocompleteOrigin"
                matAutocompleteOrigin
                [class.is-invalid]="getListControl(index)?.errors"
                [class.has-attached]="editDrugAutoTrigger.panelOpen"
              >
                <button
                  class="item__button"
                  aria-label="Remove drug"
                  (click)="deleteListControl(index); searchInputAutoTrigger.closePanel()"
                >
                  <mat-icon svgIcon="mat_x" class="item__button__icon"></mat-icon>
                </button>
                <input
                  class="item__input"
                  #editDrugField
                  #editDrugAutoTrigger="matAutocompleteTrigger"
                  (keydown.enter)="$event.preventDefault(); editDrugField.blur()"
                  (blur)="editDrugField.value.length || deleteListControl(index)"
                  [formControlName]="index"
                  [matAutocomplete]="autocomplete"
                  [matAutocompleteConnectedTo]="editOrigin"
                  [matAutocompleteDisabled]="state.typeahead.length === 0"
                />
                <button
                  tabindex="-1"
                  aria-label="Edit drug"
                  class="item__button"
                  (click)="editDrugField.focus(); $event.preventDefault()"
                >
                  <mat-icon class="item__button__icon" svgIcon="edit"></mat-icon>
                </button>
              </div>
              <elder-error-message
                *ngIf="getListControl(index)?.errors"
                [errorMessages]="this.errorMessages"
                [warningMessages]="this.warningMessages"
                [errors]="getListControl(index)?.errors"
              ></elder-error-message>
            </li>
          </div>
        </ul>
        <mat-autocomplete
          [class]="
            searchInputAutoTrigger.panelOpen && !state.mobile ? 'typeahead rounded' : 'typeahead'
          "
          #autocomplete
          autoActiveFirstOption
          (optionSelected)="chooseTerm($event)"
        >
          <div class="typeahead__options">
            <mat-option
              [value]="option[0] + option[1]"
              [title]="option[0] + option[1]"
              [class.is-pinned]="state.pinSearch"
              *ngFor="let option of state.typeahead.data | slice: 0:10"
            >
              <autocomplete-content
                [hideChips]="!searchInputAutoTrigger.panelOpen"
                [type]="option[2]"
              >
                <matched-content>{{ option[0] }}</matched-content>
                <inferred-content>{{ option[1] }}</inferred-content>
              </autocomplete-content>
            </mat-option>
          </div>
        </mat-autocomplete>
      </section>
    </div>
  </form>
</ng-container>
