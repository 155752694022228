<ng-container
  *elderLet="{
    fields: model.columns$ | async,
    mobile: layoutService.mobile$ | async,
    filter: filterService.filter$ | async
  } as state"
>
  <div
    #elderTable
    id="elderTable"
    class="drug-table--wrapper"
    (keydown)="handleGridNavigation($event)"
  >
    <cdk-table
      matSort
      class="drug-table"
      [dataSource]="model"
      [fixedLayout]="true"
      [trackBy]="trackBy"
    >
      <!--Table headers-->
      <cdk-header-row
        class="row--header"
        *cdkHeaderRowDef="state.fields; sticky: true; index as i"
      ></cdk-header-row>

      <!--Group rows-->
      <cdk-row
        class="row--content"
        [class.is-expanded]="model.checkIsExpanded(row)"
        [class.is-group-row]="row.position.isGroup"
        *cdkRowDef="let row; columns: state.fields; when: rowShown(model)"
        (click)="model.toggle(row)"
        (keydown.Enter)="model.toggle(row); $event.stopPropagation()"
        [rowDetail]="row"
        [rowDetailVisible]="model.checkIsExpanded(row)"
      ></cdk-row>

      <ng-template cdkNoDataRow>
        <div class="no-data-row">
          <elder-brand [showTitle]="false"></elder-brand>
        </div>
      </ng-template>

      <ng-container *ngFor="let field of state.fields; index as col" cdkColumnDef="{{ field }}">
        <ng-container *cdkHeaderCellDef>
          <cdk-header-cell
            class="header"
            mat-sort-header
            matTooltip="Sort by {{ field }}"
            [attr.tabIndex]="col === 0 ? '0' : '-1'"
            [keyGrid]="{ row: 0, col: this.col }"
          >
            <div class="header-content">
              {{ field | splitcase | titlecase }}
            </div>
          </cdk-header-cell>
        </ng-container>
        <ng-container *cdkCellDef="let row; index as rowIndex">
          <ng-container *ngIf="!row.position.isGroup; else groupTemplate">
            <div
              role="cell"
              class="cell-container"
              [class.is-icon-cell]="col === 0"
              [style.--left-padding]="getPadding(row)"
              [keyGrid]="{ row: rowIndex + 1, col: this.col }"
            >
              <button
                (keydown.enter)="model.toggle(row)"
                (keydown.space)="model.toggle(row)"
                class="cell-chevron"
                aria-label="Expand row"
                *ngIf="col === 0"
              >
                <svg
                  [class.is-rotated]="model.checkIsExpanded(row)"
                  class="cell-chevron-icon"
                  viewBox="0 0 24 24"
                >
                  <polygon points="8.1,3 6.9,4.1 14.8,12 6.9,19.9 8.1,21 17.1,12 8.1,3 " />
                </svg>
              </button>

              <div class="cell-title">
                {{ field | splitcase | titlecase }}
              </div>
              <div
                class="cell-content"
                [innerHtml]="
                  row.fields[field]
                    | toString
                    | sentencecase
                    | appendunit: getUnit(field)
                    | highlight: state.filter
                "
              ></div>
            </div>
          </ng-container>
          <ng-template #groupTemplate>
            <elder-group-row
              #cell
              *ngIf="col === 0"
              [class.is-expanded]="model.checkIsExpanded(row)"
              [keyGrid]="{ row: rowIndex + 1, col: 0 }"
              [style.--left-padding]="getPadding(row)"
              (keydown.ArrowRight)="toggleRow($event, row, true)"
              (keydown.ArrowLeft)="toggleRow($event, row, false)"
            >
              <group-field>{{ row.field | splitcase }}</group-field>
              <group-name>{{ row.groupHeader | sentencecase }}</group-name>
            </elder-group-row>
          </ng-template>
        </ng-container>
      </ng-container>
      <!--Header Row-->
    </cdk-table>
  </div>
</ng-container>
