<h2 mat-dialog-title>About ElderDrug</h2>
<div mat-dialog-content>
  <p>
    Elderdrug.com was created to make the AGS Beers Criteria easier to search. Published triennally
    since 2011, Beers Criteria provides a list of medications to be used with caution in adults 65+.
  </p>
  <h3 style="font-weight: 500">Citation</h3>
  <p>
    <cite
      >American Geriatrics Society 2019 Beers Criteria Update Expert Panel.
      <em
        >American Geriatrics Society 2019 Updated AGS Beers Criteria for Potentially Inappropriate
        Medication Use in Older Adults.</em
      >
      J Am Geriatr Soc. 2019; 67: 674-694</cite
    >
  </p>

  <h3 style="font-weight: 500">Contact</h3>
  <p>
    If you'd like to get in touch regarding additions, errors, changes, or programming in general,
    email me at david_hicks@unc.edu.
  </p>
</div>

<div style="justify-content: center" mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
</div>
