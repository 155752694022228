<div>
  <span class="close-button"
    ><button (click)="hide()" mat-icon-button>
      <mat-icon svgIcon="x"></mat-icon></button
  ></span>

  <h1>
    <mat-icon class="icon" [svgIcon]="icons.info"></mat-icon><span style="padding: 5px"></span
    ><span> Getting Started</span>
  </h1>

  <p *ngIf="!hideDescription">
    Elder Drug is an experimental search engine for Beers Criteria designed for healthcare
    professionals.
  </p>
  <ol>
    <li><span>Start by typing a brand or generic drug name in the search field.</span></li>
    <li>
      <span
        >Select a drug from the dropdown to add it to the search list. Multiple drugs can be added
        to a single search.</span
      >
    </li>
    <li>
      Select the
      <mat-icon aria-label="Search" class="search-icon" svgIcon="search"></mat-icon> icon to search
      for drug guidance related to your chosen drugs.
    </li>
  </ol>
</div>
