import { InjectionToken } from '@angular/core';

export const iconNames = Object.freeze({
  light: 'light',
  group: 'group',
  fields: 'fields',
  search: 'search',
  add3: 'add-3',
  filter: 'filter',
  add: 'add',
  add2: 'add-2',
  error: 'error',
  warn: 'warn',
  includes: 'includes',
  excludes: 'excludes',
  chevronright: 'chevron-right',
  overflow_menu_vertical: 'overflow_menu_vertical',
  chevronleft: 'chevron-left',
  arrow_right: 'arrow_right',
  arrow_left: 'arrow_left',
  menu: 'menu',
  heartekg: 'heart-ekg',
  chevron_down: 'chevron_down',
  kidneys: 'kidneys',
  capsule: 'capsule',
  generalhealth: 'general-health',
  elder_drug_logo: 'elder_drug_logo',
  tab_left: 'tab_left',
  tab_right: 'tab_right',
  cancel: 'cancel',
  expand_more: 'expand_more',
  x: 'x',
  mat_x: 'mat_x',
  draggable: 'draggable',
  github: 'github',
  xdelete: 'x-delete',
  edit: 'edit',
  unfold_more: 'unfold_more',
  info: 'info',
  chevron_right: 'chevron_right',
  chevron_right_alt: 'chevron-right-alt',
});
export type IconNames = typeof iconNames;
export const ICON_NAMES = new InjectionToken<Record<string, string[]>>('icons');
