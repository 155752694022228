<div class="card-container">
  <header class="header">
    <h1 class="header-h1">
      <span
        [gradientIcon]="icon"
        gradientIconType="linear"
        [gradientIconStops]="{ '0': 'var(--primary-1)', '1': 'var(--secondary-2-1)' }"
        [gradientIconAngle]="45"
        class="header-icon"
      ></span>
      <ng-content select="elder-table-title"></ng-content>
    </h1>
    <p class="header-p"><ng-content select="elder-table-description"></ng-content></p>
  </header>
  <hr class="divider" />
  <section class="table">
    <ng-content select="elder-filter-bar"></ng-content>
    <ng-content select="elder-table"></ng-content>
  </section>
</div>
