export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyDNgPNYRgq-pgh0ISC0kBDORL6Y_SfRxpU',
    authDomain: 'elder-drug.firebaseapp.com',
    databaseURL: 'https://elder-drug.firebaseio.com',
    projectId: 'elder-drug',
    storageBucket: 'elder-drug.appspot.com',
    messagingSenderId: '589933100918',
    appId: '1:589933100918:web:880a8a681d202e39c58457',
    measurementId: 'G-M7GEQ8RSJD',
  },
};
